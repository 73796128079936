export const getSamplePrompts = ({}) => {
    return [
        {
            id: "hi",
            label: "Hi! 👋",
            value: "Hi!",
        },
        {
            id: "allThoroughSummaryPerformance",
            label: "Provide a thorough summary of performance.",
            value:
                "Summarize my marketing performance. Furthermore, provide insights into what is performing well and what isn’t. Offer a summary of the top and bottom performing campaigns.",
        },
        {
            id: "allMonthlyAdBudget",
            value:
                "Load the necessary campaign-level data and suggest how I should distribute my budget across campaigns. Avoid allocating my entire budget to the highest performing campaigns or branded campaigns..",
            label: "Distribute my monthly ad budget",
        },
        {
            id: "adwordsShouldInvestSearchCampaigns",
            label: "Should I invest more in my branded search campaigns?",
            value:
                "Load the performance data of my ad campaigns from the paid platform. Identify my branded versus non-branded campaigns based on their names. Compare the aggregated performance of my branded and non-branded campaigns. Should I invest more in my branded search campaigns?",
        },
        {
            id: "compareMediaPerformance",
            label: "Compare Media Spend vs ROAS in a chart",
            value:
                "Compare the media spend across all platforms with the ROAS. Provide a chart that shows the media spend and ROAS for each platform.",
        },
        {
            id: "compareRevenue",
            label: "Compare Revenue generated by each platform",
            value:
                "Compare the revenue generated by each platform. Provide a pie chart that shows the revenue generated by each platform.",
        },
    ];
};
