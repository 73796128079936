import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomIconButton from "./CustomIcon";
import style from "./style.module.scss";
import { ReactComponent as ReportingIcon } from "assets/images/Menu/Icon-Reporting.svg";
import { ReactComponent as AnalyticsV4Icon } from "assets/images/Integrations/Icon-GoogleAnalytics-v4.svg";
import SearchConsoleIcon from "assets/images/Integrations/JSX/Logo-Google";
import GoogleBusinessProfileIcon from "assets/images/Integrations/JSX/Logo-GoogleBusinessProfile";
import FacebookIcon from "assets/images/Integrations/JSX/Logo-Facebook";
import AdsIcon from "assets/images/Integrations/JSX/Logo-Ads";
import LinkedinIcon from "assets/images/Integrations/JSX/Logo-Linkedin";
import BingIcon from "assets/images/Integrations/JSX/Logo-Bing";
import ShopifyIcon from "assets/images/Integrations/JSX/Logo-Shopify";
import BigQueryIcon from "assets/images/Integrations/JSX/Logo-BigQuery";
import TikTokIcon from "assets/images/Integrations/JSX/Logo-TikTok";
import PinterestIcon from "assets/images/Integrations/JSX/Logo-Pinterest";
import MaropostIcon from "assets/images/Integrations/JSX/Logo-Maropost";
import KlaviyoIcon from "assets/images/Integrations/JSX/Logo-Klaviyo";
import PermissionsContext from "features/Permissions";
import { makeSelectIsGenerating } from "_redux/sites/selectors";
import { makeSelectNavState } from "_redux/users/selectors";
import { ReactComponent as ReportSummaryIcon } from "assets/images/Menu/Icon-KPISummary.svg";
import { ReactComponent as ReportMakerIcon } from "assets/images/icons/Icon-AiReportBuilder.svg";
import { ReactComponent as IconCampaignGroup } from "assets/images/icons/Icon-CampaignGroup.svg";
import { ReactComponent as ReportBuilder } from "assets/images/icons/Icon-ReportBuilder.svg";
import StackAdaptIcon from "assets/images/Integrations/JSX/Logo-StackAdapt";
import ListItem from "./ListItem";
import { getPathInfo } from "lib/paths";
import { Permissions } from "features/Permissions";
import FreemiumListItem from "./FreemiumListItem";

const IconLiveData = ({ active, isNavOpen } = {}) => (
    <CustomIconButton
        isNavOpen={isNavOpen}
        active={active}
        icon={ReportingIcon}
        label="Reporting"
        arrow={true}
        hasSubNav
    />
);

const ReportingDropdown = ({
    selectedSite,
    active,
    setWrapperRef,
    history,
    isNavOpen,
    userActiveFeatures = {},
    toggleModalFeature,
    upgradeMessage,
    userRole,
}) => (
    <div className={style.dropdownContainer}>
        <IconLiveData selectedSite={selectedSite} active={active} isNavOpen={isNavOpen} />
        <PermissionsContext.Consumer>
            {({ platforms }) => (
                <div ref={setWrapperRef} className={`${style.subNavigation}`}>
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "reportBuilder")}`)
                        }
                        icon={ReportBuilder}
                        label={"Report Builder"}
                        active={history.location.pathname.split("/")[4] === "report-builder" && style.active}
                    />
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "reportSummary")}`)
                        }
                        icon={ReportSummaryIcon}
                        label={"Reporting Summary (Legacy)"}
                        active={history.location.pathname.split("/")[4] === "summary" && style.active}
                    />
                    {/* {userRole !== "reporting" && (
                        <ListItem
                            onClick={() =>
                                history.push(
                                    `/dashboard/${selectedSite}/${getPathInfo("Reports", "presentationMaker")}`,
                                )
                            }
                            icon={ReportMakerIcon}
                            label={"AI Presentation Builder"}
                            active={history.location.pathname.split("/")[4] === "report-maker" && style.active}
                        />
                    )} */}
                    {platforms.adwords && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "adwords")}`)
                            }
                            icon={AdsIcon}
                            label={"Google Ads"}
                            active={history.location.pathname.split("/")[4] === "google-ads"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.facebook && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "facebook")}`)
                            }
                            icon={FacebookIcon}
                            label={"Meta Ads"}
                            active={history.location.pathname.split("/")[4] === "facebook-ads"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.organicFacebook && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "organicFacebook")}`)
                            }
                            icon={FacebookIcon}
                            label={"Organic Facebook"}
                            active={history.location.pathname.split("/")[4] === "organic-facebook"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.linkedin && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "linkedin")}`)
                            }
                            icon={LinkedinIcon}
                            label={"LinkedIn Ads"}
                            active={history.location.pathname.split("/")[4] === "linkedin-ads"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.bing && (
                        <ListItem
                            onClick={() => history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "bing")}`)}
                            icon={BingIcon}
                            label={"Microsoft Advertising"}
                            active={history.location.pathname.split("/")[4] === "microsoft-advertising"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.analyticsV4 && (
                        <ListItem
                            onClick={() => history.push(`/dashboard/${selectedSite}/kpi/google-analytics-v4`)}
                            icon={AnalyticsV4Icon}
                            label={"Google Analytics"}
                            active={history.location.pathname.split("/")[4] === "google-analytics-v4"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.searchConsole && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "searchConsole")}`)
                            }
                            icon={SearchConsoleIcon}
                            label={"Google Search Console"}
                            active={history.location.pathname.split("/")[4] === "google-search-console"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.googleBusinessProfile && (
                        <ListItem
                            onClick={() =>
                                history.push(
                                    `/dashboard/${selectedSite}/${getPathInfo("Reports", "googleBusinessProfile")}`,
                                )
                            }
                            icon={GoogleBusinessProfileIcon}
                            label={"Google Business Profile"}
                            active={history.location.pathname.split("/")[4] === "google-business-profile"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.shopify && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "shopify")}`)
                            }
                            icon={ShopifyIcon}
                            label={"Shopify"}
                            active={history.location.pathname.split("/")[4] === "shopify"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.tiktok && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "tiktok")}`)
                            }
                            icon={TikTokIcon}
                            label="TikTok"
                            active={history.location.pathname.split("/")[4] === "tiktok"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.pinterest && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "pinterest")}`)
                            }
                            icon={PinterestIcon}
                            label="Pinterest"
                            active={history.location.pathname.split("/")[4] === "pinterest"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.bigQuery && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "bigQuery")}`)
                            }
                            icon={BigQueryIcon}
                            label="BigQuery"
                            active={history.location.pathname.split("/")[4] === "bigQuery"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.maropost && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "maropost")}`)
                            }
                            icon={MaropostIcon}
                            label="Maropost"
                            active={history.location.pathname.split("/")[4] === "maropost"}
                            className={style.fullColor}
                        />
                    )}
                    {platforms.klaviyo && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "klaviyo")}`)
                            }
                            icon={KlaviyoIcon}
                            label="Klaviyo"
                            active={history.location.pathname.split("/")[4] === "klaviyo"}
                        />
                    )}
                    {platforms.stackAdapt && (
                        <ListItem
                            onClick={() =>
                                history.push(`/dashboard/${selectedSite}/${getPathInfo("Reports", "stackAdapt")}`)
                            }
                            icon={StackAdaptIcon}
                            label={"StackAdapt"}
                            active={history.location.pathname.split("/")[4] === "stackAdapt"}
                            className={style.fullColor}
                        />
                    )}
                </div>
            )}
        </PermissionsContext.Consumer>
    </div>
);

export default compose(
    withRouter,
    connect((state) => ({
        isNavOpen: makeSelectNavState()(state),
        isLoading: makeSelectIsGenerating(["seo_anomalies", "keywords", "competitors", "health_checks"])(state),
    })),
)(ReportingDropdown);
