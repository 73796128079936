import { api } from "lib/googleBusinessProfile";
import types from "./types";

const getGoogleBusinessProfileData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.GOOGLE_BUSINESS_PROFILE_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
            abortController,
        }),
    });
};

export default {
    getGoogleBusinessProfileData,
};
