import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Permissions } from "features/Permissions";
import style from "./style.module.scss";
import CustomIconButton from "./CustomIcon";
import { ReactComponent as AnalyzeIcon } from "assets/images/icons/Icon-Analyze.svg";
import { makeSelectIsGenerating } from "_redux/sites/selectors";
import { makeSelectNavState } from "_redux/users/selectors";
import { ReactComponent as Insights } from "assets/images/icons/Icon-Suggestions.svg";
import { ReactComponent as AuditReport } from "assets/images/Menu/Icon-AuditReport.svg";
import { ReactComponent as InsightsList } from "assets/images/icons/Icon-Insights-List.svg";
import IconBenchmark from "assets/images/icons/JSX/Icon-Benchmark";
import ChatGPTIcon from "assets/images/icons/JSX/Icon-ChatGPT";
import ListItem from "./ListItem";
import FreemiumListItem from "./FreemiumListItem";
import AnomaliesItem from "./AnomaliesItem";
import { ReactComponent as LightningBolt } from "assets/images/Menu/Icon-Anomalies.svg";
import { ReactComponent as PpcBudgets } from "assets/images/icons/Icon-PPCBudgets.svg";
import { ReactComponent as PpcBudgetPlanner } from "assets/images/icons/Icon-BudgetPlanner.svg";
import { getPathInfo } from "lib/paths";

const IconAnalyze = ({ active, isLoading, dot, isNavOpen } = {}) => (
    <>
        <CustomIconButton
            key="CompetiorIcon"
            isNavOpen={isNavOpen}
            active={active}
            icon={AnalyzeIcon}
            label={"Insights"}
            isLoading={isLoading}
            dot={dot}
            hasSubNav
        />
    </>
);

function InsightsDropdown({
    isLoadingInsights,
    selectedSite,
    setWrapperRef,
    history,
    isNavOpen,
    toggleModalFeature,
    upgradeMessage,
    userType,
}) {
    const anomaliesActive = history.location.pathname.split("/")[3] === "anomalies";
    const ppcAuditActive = history.location.pathname.split("/")[3] === "ppcaudit";
    const benchmarkActive = history.location.pathname.split("/")[3] === "benchmarks";
    const insightsActive = history.location.pathname.split("/")[3] === "insights";
    const auditreportActive = history.location.pathname.split("/")[3] === "audit-report";
    const budgetPlannerActive = history.location.pathname.split("/")[3] === "budgetPlanner";
    const chatGptActive = history.location.pathname.split("/")[3] === "chatGpt";

    const active =
        ppcAuditActive ||
        anomaliesActive ||
        benchmarkActive ||
        insightsActive ||
        auditreportActive ||
        budgetPlannerActive ||
        chatGptActive;

    return (
        <div className={style.dropdownContainer}>
            <IconAnalyze
                isLoading={isLoadingInsights}
                isNavOpen={isNavOpen}
                active={active}
                selectedSite={selectedSite}
            />

            <div ref={setWrapperRef} className={`${style.subNavigation}`}>
                <Permissions
                    actions={"ACTION_PLATFORMS"}
                    fallback={({ ACTION_PLATFORMS } = {}) => {
                        if (ACTION_PLATFORMS === upgradeMessage) {
                            return (
                                <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Insights Summary">
                                    <FreemiumListItem
                                        icon={InsightsList}
                                        label="Insights Summary"
                                        isNavOpen={isNavOpen}
                                        onClick={() =>
                                            toggleModalFeature({
                                                isOpen: true,
                                                feature: "Insights Summary",
                                            })
                                        }
                                    />
                                </li>
                            );
                        }

                        return null;
                    }}
                >
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Insights", "insights")}`)
                        }
                        icon={InsightsList}
                        label={"Insights Summary"}
                        active={history.location.pathname.split("/")[3] === "insights"}
                    />
                </Permissions>
                {userType !== "appSumoUser" && (
                    <Permissions
                        actions={"ACTION_WEEKLY_ANOMALIES"}
                        fallback={({ ACTION_WEEKLY_ANOMALIES } = {}) => {
                            if (ACTION_WEEKLY_ANOMALIES === upgradeMessage) {
                                return (
                                    <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Weekly Anomalies">
                                        <FreemiumListItem
                                            label="Weekly Anomalies"
                                            icon={LightningBolt}
                                            onClick={() =>
                                                toggleModalFeature({
                                                    isOpen: true,
                                                    feature: "Weekly Anomalies",
                                                    video: "k7c2egjkVm0",
                                                })
                                            }
                                        />
                                    </li>
                                );
                            }
                        }}
                    >
                        <div id="anomaliesIconHelp">
                            <AnomaliesItem
                                active={history.location.pathname.split("/")[3] === "anomalies"}
                                selectedSite={selectedSite}
                            />
                        </div>
                    </Permissions>
                )}
                <Permissions
                    actions={"ACTION_AUDIT_REPORT"}
                    fallback={({ ACTION_AUDIT_REPORT } = {}) => {
                        if (ACTION_AUDIT_REPORT === upgradeMessage) {
                            return (
                                <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Audit Report">
                                    <FreemiumListItem
                                        icon={AuditReport}
                                        label="Audit Report"
                                        isNavOpen={isNavOpen}
                                        onClick={() =>
                                            toggleModalFeature({
                                                isOpen: true,
                                                feature: "Audit Report",
                                            })
                                        }
                                    />
                                </li>
                            );
                        }

                        return null;
                    }}
                >
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Insights", "auditReport")}`)
                        }
                        icon={AuditReport}
                        label={"Audit Report"}
                        active={history.location.pathname.split("/")[3] === "audit-report"}
                    />
                </Permissions>
                <Permissions
                    actions={"ACTION_BENCHMARKS"}
                    fallback={({ ACTION_BENCHMARKS } = {}) => {
                        if (ACTION_BENCHMARKS === upgradeMessage) {
                            return (
                                <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Data Benchmarking">
                                    <FreemiumListItem
                                        icon={Insights}
                                        label="Data Benchmarking"
                                        isNavOpen={isNavOpen}
                                        onClick={() =>
                                            toggleModalFeature({
                                                isOpen: true,
                                                feature: "Data Benchmarking",
                                            })
                                        }
                                    />
                                </li>
                            );
                        }

                        return null;
                    }}
                >
                    <ListItem
                        isLoading={isLoadingInsights}
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Insights", "benchmarks")}`)
                        }
                        icon={IconBenchmark}
                        label={"Data Benchmarking"}
                        active={history.location.pathname.split("/")[3] === "benchmarks"}
                    />
                </Permissions>
                {/* {userActiveFeatures["budgetPlanner"] && (
                    <>
                        <Permissions
                            actions={"ACTION_PPCAUDIT"}
                            fallback={({ ACTION_PPCAUDIT } = {}) => {
                                if (ACTION_PPCAUDIT === upgradeMessage) {
                                    return (
                                        <li
                                            data-for="navTooltip"
                                            data-tip-disable={isNavOpen}
                                            data-tip="Budget Planner"
                                        >
                                            <FreemiumListItem
                                                icon={PpcBudgetPlanner}
                                                label="Budget Planner"
                                                isNavOpen={isNavOpen}
                                                onClick={() =>
                                                    toggleModalFeature({
                                                        isOpen: true,
                                                        feature: "Budget Planner",
                                                    })
                                                }
                                            />
                                        </li>
                                    );
                                }

                                return null;
                            }}
                        >
                            <ListItem
                                isLoading={isLoadingInsights}
                                onClick={() =>
                                    history.push(
                                        `/dashboard/${selectedSite}/${getPathInfo("Insights", "budgetPlanner")}`,
                                    )
                                }
                                icon={PpcBudgetPlanner}
                                label={"Budget Planner"}
                                active={history.location.pathname.split("/")[3] === "budgetPlanner"}
                            />
                        </Permissions>
                        <Permissions
                            actions={"ACTION_PPCAUDIT"}
                            fallback={({ ACTION_PPCAUDIT } = {}) => {
                                if (ACTION_PPCAUDIT === upgradeMessage) {
                                    return (
                                        <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Suggestions">
                                            <FreemiumListItem
                                                icon={PpcBudgets}
                                                label={"Budget Pacer"}
                                                active={ppcAuditActive}
                                                onClick={() => {
                                                    toggleModalFeature({
                                                        isOpen: true,
                                                        feature: "Budget Pacer",
                                                        video: "OsJwwot4yU4",
                                                    });
                                                }}
                                            />
                                        </li>
                                    );
                                }

                                return null;
                            }}
                        >
                            <ListItem
                                onClick={() =>
                                    history.push(`/dashboard/${selectedSite}/${getPathInfo("Insights", "ppcaudit")}`)
                                }
                                icon={PpcBudgets}
                                label={"Budget Pacer"}
                                active={ppcAuditActive}
                            />
                        </Permissions>
                    </>
                )} */}
            </div>
        </div>
    );
}

export default compose(
    withRouter,
    connect((state) => ({
        isNavOpen: makeSelectNavState()(state),
        isLoadingSegments: makeSelectIsGenerating(["segments"]),
        isLoadingForecast: makeSelectIsGenerating(["forecasting"]),
        isLoadingInsights: makeSelectIsGenerating(["segments", "forecasting", "weekly_anomalies"]),
    })),
)(InsightsDropdown);
