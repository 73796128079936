import React from "react";
import Avatar from "material-ui/Avatar";
import style from "./style.module.scss";
import { branch, renderComponent } from "recompose";

// const AvatarImage = ({ title, avatarImage, style }) => (
//     <Avatar style={{background: '#FFCA28', ...style}} renderUnless={!avatarImage}>
//         { title.substring(0, 1).toUpperCase() }
//     </Avatar>
// )

const AvatarImage = branch(
    ({ avatarImage }) => {
        return avatarImage;
    },
    renderComponent(({ avatarImage, style }) => (
        <Avatar src={avatarImage} style={{ background: "#FFCA28", ...style }} />
    )),
    renderComponent(({ avatarImage, style, title }) => (
        <Avatar style={{ background: "#FFCA28", ...style }}>{title.substring(0, 1).toUpperCase()}</Avatar>
    )),
)();

const Simple = () => <div></div>;

const AvatarHeading = ({ username, subtitle, avatarImage }) => (
    <div className={style.avatarHeader}>
        <AvatarImage title={username} avatarImage={avatarImage} />
        <div className={style.avatarHeaderInfo}>
            <p className={style.avatarHeaderUsername}>{username}</p>
            <p className={style.avatarHeaderSubtitle}>{subtitle}</p>
        </div>
    </div>
);

export { Simple, AvatarHeading };
