export default [
    "cost",
    "cpl",
    "cpa",
    "cpc",
    "cpv",
    "cpm",
    "platformCpl",
    "cplPaid",
    "cpaPaid",
    "position",
    "bounceRate",
    "bounces",
    "discounts",
    "returns",
    "frequency",
    "cpp",
    "costPerPurchase",
    "costPerCheckout",
    "costPerAddToCart",
    "metaCpp",
    "websiteCpp",
    "cpLeadGrouped",
    "costPer_fb_pixel_lead",
    "costPerSubscription",
    "costPerPageViewPage",
    "costPerProductViewPage",
    "costPerAddToCartPage",
    "costPerCompletePaymentPage",
    "costPerCheckoutPage",
    "costPerPageView",
    "costPerCompletePayment",
    "costPerProductView",
    "unsubscribed",
    "flowUnsubscribed",
    "listUnsubscribed",
    "cpe",
    "revenueFee",
];
