import types from "./types";
import { getPlatformCampaignsReport } from "lib/reports/campaigns/api";

const getLinkedinData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.LINKEDIN_REPORT,
        promise: getPlatformCampaignsReport({
            platform: "linkedin",
            dateRange,
            accountId,
            abortController,
        }),
    });
};

export default {
    getLinkedinData,
};
