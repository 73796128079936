import { getChannelMetrics, getAllAdwordsMetrics } from "lib/reports/campaigns/constants";
import { KpiAPI } from "lib/apiKpi";
import { prepareDateRange } from "./helpers";

export function getPlatformCampaignsReport({ platform, dateRange, accountId, abortController }) {
    if (!platform) {
        return {};
    }

    const [{ start: startDate, end: endDate }, { start: compareToStartDate, end: compareToEndDate }] = prepareDateRange(
        dateRange,
    );
    const metrics =
        platform === "adwords"
            ? getAllAdwordsMetrics()
            : getChannelMetrics(platform).map((metric) => `${platform}-${metric}`) || [];

    return KpiAPI({
        method: "GET",
        url: `/account/${accountId}`,
        query: {
            date: endDate,
            startDate,
            endDate,
            compareToStartDate,
            compareToEndDate,
            metrics: JSON.stringify({
                metrics,
            }),
            compareTo: `${compareToStartDate}-${compareToEndDate}`,
            includeCampaignData: true,
        },
        abortController,
    }).then(({ data }) => data);
}
