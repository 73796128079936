import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    report: {},
    reportAdGroups: [],
    adsReport: [],
    adwordsLoading: false,
    adwordsAdgroupLoading: false,
    searchTermsReport: [],
    selectedFilterAdGroup: {},
    selectedFilterCampaign: {},
    campaignAssetReports: {},
    assetGroupReports: {},
    assetGroupAssets: {},
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ADWORDS_CAMPAIGN_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adwordsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    adwordsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    report: action.payload,
                }),
            });
        },
        [types.ADWORDS_ADGROUP_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adwordsAdgroupLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    adwordsAdgroupLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    reportAdGroups: action.payload,
                }),
            });
        },
        [types.ADWORDS_ADS_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    adsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    adsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    adsReport: action.payload,
                }),
            });
        },
        [types.ADWORDS_SEARCH_TERMS_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    searchTermsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    searchTermsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    searchTermsReport: action.payload,
                }),
            });
        },
        [types.SET_SEARCH_TERMS_ADGROUP_FILTER]: (state, action) => {
            return {
                ...state,
                selectedFilterAdGroup: action.payload,
            };
        },
        [types.SET_SEARCH_TERMS_CAMPAIGN_FILTER]: (state, action) => {
            return {
                ...state,
                selectedFilterCampaign: action.payload,
            };
        },
        [types.ADWORDS_CAMPAIGN_ASSET_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignAssetReportsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    campaignAssetReportsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    campaignAssetReports: action.payload,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    campaignAssetReportsError: true,
                }),
            });
        },
        [types.RESET_CAMPAIGN_ASSET_REPORTS]: (state, action) => {
            return {
                ...state,
                campaignAssetReports: {},
            };
        },
        [types.ADWORDS_ASSET_GROUP_REPORTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    assetGroupReportsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    assetGroupReportsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    assetGroupReports: action.payload,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    assetGroupReportsError: true,
                }),
            });
        },
        [types.RESET_ADWORDS_ASSET_GROUP_REPORTS]: (state, action) => {
            return {
                ...state,
                assetGroupReports: {},
            };
        },
        [types.ADWORDS_ASSET_GROUP_ASSETS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    assetGroupAssetsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    assetGroupAssetsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    assetGroupAssets: action.payload,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    assetGroupAssetsError: true,
                }),
            });
        },
        [types.RESET_ADWORDS_ASSET_GROUP_ASSETS]: (state, action) => {
            return {
                ...state,
                assetGroupAssets: {},
            };
        },
    }),
);
