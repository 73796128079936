import decode from "jwt-decode";
import { store } from "../_redux";
import { populateUser, initUser } from "../_redux/users";
import { MorphioAPI } from "./api";
import compose from "lodash/fp/flow";

import { trackGoal, mixpanel } from "../components/Mixpanel";

export const identifyUser = async ({ data = {} }) => {
    const {
        user_id = null,
        sub,
        email,
        last_login,
        subscription: { isTrial, isFree, name, trialExpiry } = {},
        app_metadata: { role, parent } = {},
        user_metadata: { nickname: userName, company } = {},
    } = data;

    const userId = user_id ? user_id : sub;

    if (userId) {
        const additionalInfo = {
            Role: role,
            "Is Trial": isTrial,
            "Is Free": isFree,
            "Trail Expiry": trialExpiry,
            "Last Login": last_login,
            Subscription: name,
            Company: company,
            Group: parent || sub,
        };

        await mixpanel.people.set({
            $name: userName,
            $email: email,
            ...additionalInfo,
        });
        await mixpanel.identify(userId);

        return true;
    }

    return false;
};

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_CUSTOM_DOMAIN, REACT_APP_AUTH0_URL } = process.env;

const NEXT_PATH_KEY = "next_path";
export const ID_TOKEN_KEY = "id_token";
const ACCESS_TOKEN_KEY = "access_token";
const PROFILE_KEY = "profile";
const CODE = "code";
const HIDE_ALERTS_KEY = "hideAlerts";

export const LOGOUT_ROUTE = "/logout";

export const USER_METADATA = `user_metadata`;
export const APP_METADATA = `app_metadata`;

if (!REACT_APP_AUTH0_CLIENT_ID || !REACT_APP_AUTH0_URL.replace("https://", "") || !REACT_APP_AUTH0_CUSTOM_DOMAIN) {
    throw new Error("Please define `REACT_APP_AUTH0_CLIENT_ID` and `REACT_APP_AUTH0_DOMAIN` in your .env file");
}

export function logout() {
    store.dispatch({ type: "LOGOUT" });
    store.dispatch({ type: "RESET_APP" });
    window.Intercom("shutdown");
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    return localStorage.clear();
}

const getCookie = function(name) {
    console.log("document.cookie", document.cookie);
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
};

function eraseCookie(name) {
    document.cookie = name + "=; expires = Thu, 01 Jan 1970 00:00:00 GMT; domain=.hawke.ai";
}

export async function cookieLogin() {
    const cookieName = "_auth0";

    logout();

    if (getCookie(cookieName)) {
        const { access_token, id_token } = compose(decodeURIComponent, JSON.parse)(getCookie(cookieName));

        setIdToken(id_token);
        setAccessToken(access_token);
        return await getUserInfo()
            .then(({ data }) => setProfile(data))
            .finally(() => {
                eraseCookie(cookieName);
                return true;
            })
            .catch(() => {
                logout();
            });
    }

    throw new Error("Unauthorized");
}

export function requireAuth({ location: { pathname, search } }, replace) {
    if (!isLoggedIn()) {
        return replace({
            pathname: LOGOUT_ROUTE,
            search: `redirect=${pathname}${search}`,
        });
    } else {
        const data = getProfile();

        // THIS ACTION STARTS UP THE SOCKET THAT CONNECTS THE USER....
        // DONT REMOVE IT AGAIN YOU DUMMY
        return store.dispatch(initUser({ user: data }));
    }
}

export const getUserInfo = () =>
    MorphioAPI({
        url: "/user/self",
        method: "GET",
    }).then(async ({ data }) => {
        try {
            const preload = await MorphioAPI({
                url: "/preload",
                method: "POST",
                data: {
                    userId: data.sub,
                },
            });
        } catch (e) {
            console.error("Error preloading data", e);
        }
        return { data };
    });

export const googleAuthenticate = ({ code, state }) => {
    return MorphioAPI({
        url: "login/google",
        method: "POST",
        data: {
            code,
            state,
            frontEndUrl: `${window.location.protocol}//${window.location.hostname}${
                window.location.port ? ":" + window.location.port : ""
            }`,
        },
    }).then(({ data }) => {
        if (data.linkedAccount) {
            return { linkedAccount: data.linkedAccount };
        }
        setAccessToken(data.access_token);
        setIdToken(data.id_token);

        return getUserInfo().then(async ({ data = {} }) => {
            const { user_metadata: { company } = {}, app_metadata: { parent } = {} } = data;

            await identifyUser({ data });

            setProfile(data, false);

            if (parent) {
                trackGoal(
                    "Login",
                    {
                        Method: "Google",
                    },
                    {
                        additionalMessage: `| Method:Google | Sub-User | Parent:${parent}`,
                        sendToSlack: true,
                    },
                );
            } else {
                trackGoal(
                    "Login",
                    {
                        Method: "Google",
                    },
                    {
                        additionalMessage: `| Method:Google | Super-User `,
                        sendToSlack: true,
                    },
                );
            }

            return data;
        });
    });
};

export const authenticate = ({ email, password } = {}) => {
    return MorphioAPI({
        url: "login",
        method: "POST",
        data: {
            email,
            password,
        },
    })
        .then(({ data }) => {
            setAccessToken(data.access_token);
            setIdToken(data.id_token);
            return getUserInfo();
        })
        .then(async ({ data }) => {
            const { user_metadata: { company } = {}, app_metadata: { parent } = {} } = data;

            await identifyUser({ data });

            setProfile(data, false);
            if (parent) {
                await trackGoal(
                    "Login",
                    {
                        Method: "Local",
                    },
                    {
                        additionalMessage: `| Method:Local |  Sub-User | Parent:${parent}`,
                        sendToSlack: true,
                    },
                );
            } else {
                await trackGoal(
                    "Login",
                    {
                        Method: "Local",
                    },
                    {
                        additionalMessage: `| Method:Local | Super-User `,
                        sendToSlack: true,
                    },
                );
            }

            return data;
        });
};

export function fetchAndSetProfile() {
    return getUserInfo().then(({ data }) => {
        setProfile(data, true);
    });
}

export function setProfile(profile, dispatch = true) {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));

    if (dispatch) {
        return store.dispatch(populateUser({ user: profile }));
    }

    return profile;
}

export function getProfile() {
    return JSON.parse(localStorage.getItem(PROFILE_KEY));
}

export function setIdToken(idToken) {
    localStorage.setItem(ID_TOKEN_KEY, idToken);
}

export function setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export function _getIdToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getCode() {
    return localStorage.getItem(CODE);
}

export function setCode(code) {
    return localStorage.setItem(CODE, code);
}

export function getHideAlerts() {
    const hideAlerts = localStorage.getItem(HIDE_ALERTS_KEY);
    return JSON.parse(hideAlerts);
}

export function setHideAlerts(hideAlerts) {
    return localStorage.setItem(HIDE_ALERTS_KEY, hideAlerts);
}

export function clearNextPath() {
    localStorage.removeItem(NEXT_PATH_KEY);
}

export function isLoggedIn() {
    const idToken = _getIdToken();
    return idToken && !isTokenExpired(idToken);
}

function getTokenExpirationDate(encodedToken) {
    let sessionExpiryDate;
    try {
        const { exp } = decode(encodedToken);

        sessionExpiryDate = exp;
    } catch (e) {
        sessionExpiryDate = null;
    }

    const date = new Date(0);
    date.setUTCSeconds(sessionExpiryDate);

    return date;
}

export function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}
