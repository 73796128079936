import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { merge, get } from "lodash";

export const defaultGoals = {
    // GA4
    ...[
        "analyticsV4",
        "analyticsV4Direct",
        "analyticsV4OrganicSearch",
        "analyticsV4OrganicSocial",
        "analyticsV4PaidSocial",
        "analyticsV4Email",
        "analyticsV4Affiliates",
        "analyticsV4Referral",
        "analyticsV4PaidSearch",
        "analyticsV4Display",
        "analyticsV4Video",
    ].reduce(
        (cache, p) => ({
            ...cache,
            [`${p}-sessions`]: { active: false },
            [`${p}-conversions`]: { active: false },
            [`${p}-transactions`]: { active: false },
            [`${p}-conversionRate`]: { active: false },
            [`${p}-transactionRate`]: { active: false },
            [`${p}-engagementRate`]: { active: false },
            [`${p}-totalRevenue`]: { active: false },
            [`${p}-avgOrderValue`]: { active: false },
            [`${p}-totalUsers`]: { active: false },
            [`${p}-activeUsers`]: { active: false },
            [`${p}-engagedSessions`]: { active: false },
            [`${p}-bounces`]: { active: false },
            [`${p}-averageSessionDuration`]: { active: false },
            [`${p}-screenPageViewsPerSession`]: { active: false },
            [`${p}-avgEngagementTime`]: { active: false },
            [`${p}-avgEngagementTimePerSession`]: { active: false },
            [`${p}-addToCarts`]: { active: false },
            [`${p}-newUsers`]: { active: false },
        }),
        {},
    ),
    // adwords channels
    ...["adwordsDisplay", "adwordsSearch", "adwordsYouTubeVideos", "adwordsSearchPartners", "adwordsCross"].reduce(
        (cache, p) => ({
            ...cache,
            [`${p}-cost`]: { active: false },
            [`${p}-impressions`]: { active: false },
            [`${p}-clicks`]: { active: false },
            [`${p}-ctr`]: { active: false },
            [`${p}-conversions`]: { active: false },
            [`${p}-cpl`]: { active: false },
            [`${p}-cpc`]: { active: false },
            [`${p}-conversionRate`]: { active: false },
            [`${p}-cpm`]: { active: false },
            [`${p}-interactions`]: { active: false },
            [`${p}-videoViewRate`]: { active: false },
            [`${p}-roas`]: { active: false },
            [`${p}-roasByTime`]: { active: false },
            [`${p}-conversionValue`]: { active: false },
            [`${p}-phoneCalls`]: { active: false },
            [`${p}-phoneImpressions`]: { active: false },
            [`${p}-phoneThroughRate`]: { active: false },
        }),
        {},
    ),
    // adwords
    "adwords-cost": {
        active: false,
    },
    "adwords-videoViewRate": {
        active: false,
    },
    "adwords-interactions": {
        active: false,
    },
    "adwords-impressions": {
        active: false,
    },
    "adwords-clicks": {
        active: false,
    },
    "adwords-ctr": {
        active: false,
    },
    "adwords-conversions": {
        active: false,
    },
    "adwords-cpl": {
        active: false,
    },
    "adwords-cpc": {
        active: false,
    },
    "adwords-conversionRate": {
        active: false,
    },
    "adwords-cpm": {
        active: false,
    },
    "adwords-conversionValue": {
        active: false,
    },
    "adwords-viewThroughConv": {
        active: false,
    },
    "adwords-conversionsByConversionDate": {
        active: false,
    },
    "adwords-conversionsValueByConversionDate": {
        active: false,
    },
    "adwords-conversionRateByConversionDate": {
        active: false,
    },
    "adwords-ADD_TO_CART": {
        active: false,
    },
    "adwords-BEGIN_CHECKOUT": {
        active: false,
    },
    "adwords-BOOK_APPOINTMENT": {
        active: false,
    },
    "adwords-CONTACT": {
        active: false,
    },
    "adwords-DOWNLOAD": {
        active: false,
    },
    "adwords-ENGAGEMENT": {
        active: false,
    },
    "adwords-GET_DIRECTIONS": {
        active: false,
    },
    "adwords-IMPORTED_LEAD": {
        active: false,
    },
    "adwords-LEAD": {
        active: false,
    },
    "adwords-OUTBOUND_CLICK": {
        active: false,
    },
    "adwords-PAGE_VIEW": {
        active: false,
    },
    "adwords-PHONE_CALL_LEAD": {
        active: false,
    },
    "adwords-PURCHASE": {
        active: false,
    },
    "adwords-REQUEST_QUOTE": {
        active: false,
    },
    "adwords-SIGNUP": {
        active: false,
    },
    "adwords-STORE_SALE": {
        active: false,
    },
    "adwords-STORE_VISIT": {
        active: false,
    },
    "adwords-SUBMIT_LEAD_FORM": {
        active: false,
    },
    "adwords-SUBSCRIBE_PAID": {
        active: false,
    },
    "adwords-roas": {
        active: false,
    },
    "adwords-roasByTime": {
        active: false,
    },
    "adwords-phoneCalls": {
        active: false,
    },
    "adwords-phoneImpressions": {
        active: false,
    },
    "adwords-phoneThroughRate": {
        active: false,
    },
    //facebook
    "facebook-cost": {
        active: false,
    },
    "facebook-leadGrouped": {
        active: false,
    },
    "facebook-impressions": {
        active: false,
    },
    "facebook-clicksAll": {
        active: false,
    },
    "facebook-clicks": {
        active: false,
    },
    "facebook-ctr": {
        active: false,
    },
    "facebook-ctrAll": {
        active: false,
    },
    "facebook-landingPageViews": {
        active: false,
    },
    "facebook-conversions": {
        active: false,
    },
    "facebook-cpl": {
        active: false,
    },
    "facebook-cpc": {
        active: false,
    },
    "facebook-conversionRate": {
        active: false,
    },
    "facebook-fb_pixel_add_to_cart": {
        active: false,
    },
    "facebook-fb_pixel_initiate_checkout": {
        active: false,
    },
    "facebook-fb_pixel_purchase_value": {
        active: false,
    },
    "facebook-purchaseValue": {
        active: false,
    },
    "facebook-fb_pixel_add_to_cart_value": {
        active: false,
    },
    "facebook-fb_pixel_initiate_checkout_value": {
        active: false,
    },
    "facebook-onsite_conversion-purchase": { active: false },
    "facebook-onsite_conversion-purchase_value": { active: false },
    "facebook-cpm": {
        active: false,
    },
    "facebook-reach": { active: false },
    "facebook-frequency": { active: false },
    "facebook-engagement": { active: false },
    "facebook-outboundClicks": {
        active: false,
    },
    "facebook-outboundCtr": { active: false },
    "facebook-aov": { active: false },
    "facebook-cpp": { active: false },
    "facebook-websiteCpp": { active: false },
    "facebook-metaCpp": { active: false },
    "facebook-roas": { active: false },
    "facebook-websiteRoas": { active: false },
    "facebook-metaRoas": { active: false },
    "facebook-contentViews": { active: false },
    "facebook-contacts": { active: false },
    "facebook-fb_pixel_purchase": {
        active: false,
    },
    "facebook-purchases": {
        active: false,
    },
    "facebook-omniAddToCart": {
        active: false,
    },
    "facebook-fb_pixel_complete_registration": { active: false },
    "facebook-fb_pixel_complete_registration_value": { active: false },
    "facebook-fb_pixel_lead": { active: false },
    "facebook-fb_pixel_lead_value": { active: false },
    "facebook-costPer_fb_pixel_lead": { active: false },
    "facebook-cpLeadGrouped": {
        active: false,
    },
    "facebook-subscriptions": {
        active: false,
    },
    "facebook-subscriptionValue": {
        active: false,
    },
    "facebook-costPerSubscription": {
        active: false,
    },
    "facebook-costPerAddToCart": { active: false },
    //linkedin
    "linkedin-cost": {
        active: false,
    },
    "linkedin-impressions": {
        active: false,
    },
    "linkedin-clicks": {
        active: false,
    },
    "linkedin-conversions": {
        active: false,
    },
    "linkedin-conversionRate": {
        active: false,
    },
    "linkedin-cpl": {
        active: false,
    },
    "linkedin-cpc": {
        active: false,
    },
    "linkedin-cpm": {
        active: false,
    },
    "linkedin-ctr": {
        active: false,
    },
    "linkedin-oneClickLeads": {
        active: false,
    },
    "linkedin-oneClickLeadFormOpens": { active: false },
    "linkedin-viewConversions": { active: false },
    "linkedin-clickConversions": { active: false },
    "linkedin-engagement": { active: false },
    "linkedin-oneClickLeadsConversionRate": { active: false },
    "linkedin-costPerOneClickLeads": { active: false },
    "linkedin-engagementRate": { active: false },
    "linkedin-costPerClickAndView": { active: false },
    "bing-cost": {
        active: false,
    },
    "bing-impressions": {
        active: false,
    },
    "bing-clicks": {
        active: false,
    },
    "bing-ctr": {
        active: false,
    },
    "bing-conversions": {
        active: false,
    },
    "bing-cpl": {
        active: false,
    },
    "bing-cpc": {
        active: false,
    },
    "bing-conversionRate": {
        active: false,
    },
    "bing-cpm": {
        active: false,
    },
    "bing-viewThroughConversions": {
        active: false,
    },
    "bing-revenue": {
        active: false,
    },
    "bing-roas": {
        active: false,
    },
    "shopify-revenue": {
        active: false,
    },
    "shopify-transactions": {
        active: false,
    },
    "shopify-avgOrderValue": {
        active: false,
    },
    "shopify-ltv": {
        active: false,
    },
    "shopify-items": {
        active: false,
    },
    "shopify-newCustomerOrders": {
        active: false,
    },
    "shopify-newCustomerGMV": {
        active: false,
    },
    "shopify-newCustomerAOV": {
        active: false,
    },
    "shopify-returningCustomerOrders": {
        active: false,
    },
    "shopify-returningCustomerGMV": {
        active: false,
    },
    "shopify-returningCustomerAOV": {
        active: false,
    },
    "shopify-grossSales": {
        active: false,
    },
    "shopify-discounts": {
        active: false,
    },
    "shopify-shipping": {
        active: false,
    },
    "shopify-taxes": {
        active: false,
    },
    "shopify-returns": {
        active: false,
    },
    "shopify-netSales": {
        active: false,
    },
    "shopify-totalSales": {
        active: false,
    },
    "shopify-gmv": {
        active: false,
    },
    "shopify-aov": {
        active: false,
    },
    "shopify-averageSalePrice": {
        active: false,
    },
    "shopify-itemsPerTransaction": {
        active: false,
    },
    "custom-cost": {
        active: true,
    },
    "custom-conversions": {
        active: true,
    },
    "custom-clicks": {
        active: true,
    },
    "custom-impressions": {
        active: true,
    },
    "custom-roas": {
        active: true,
    },
    "custom-paid-clicks": {
        active: true,
    },
    "googleBusinessProfile-callClicks": {
        active: false,
    },
    "googleBusinessProfile-websiteClicks": {
        active: false,
    },
    "googleBusinessProfile-businessBookings": {
        active: false,
    },
    "googleBusinessProfile-businessFoodOrders": {
        active: false,
    },
    "googleBusinessProfile-averageRating": {
        active: false,
    },
    "googleBusinessProfile-totalReviewCount": {
        active: false,
    },
    "googleBusinessProfile-viewsByDesktop": {
        active: false,
    },
    "googleBusinessProfile-viewsByMobile": {
        active: false,
    },
    "tiktok-cpl": {
        active: false,
    },
    "tiktok-cost": {
        active: false,
    },
    "tiktok-conversions": {
        active: false,
    },
    "tiktok-conversionRate": {
        active: false,
    },
    "tiktok-clicks": {
        active: false,
    },
    "tiktok-videoViews": {
        active: false,
    },
    "tiktok-impressions": {
        active: false,
    },
    "tiktok-ctr": {
        active: false,
    },
    "tiktok-cpc": {
        active: false,
    },
    "tiktok-averageWatchTime": {
        active: false,
    },
    "tiktok-videoViewsAt50": {
        active: false,
    },
    "tiktok-watched2sVideo": {
        active: false,
    },
    "tiktok-cpm": {
        active: false,
    },
    "tiktok-reach": {
        active: false,
    },
    "tiktok-frequency": {
        active: false,
    },
    "tiktok-totalPageViewPage": {
        active: false,
    },
    "tiktok-costPerPageViewPage": {
        active: false,
    },
    "tiktok-productViewsPage": {
        active: false,
    },
    "tiktok-costPerProductViewPage": {
        active: false,
    },
    "tiktok-addToCartPage": {
        active: false,
    },
    "tiktok-costPerAddToCartPage": {
        active: false,
    },
    "tiktok-completePaymentPage": {
        active: false,
    },
    "tiktok-costPerCompletePaymentPage": {
        active: false,
    },
    "tiktok-checkoutsPage": {
        active: false,
    },
    "tiktok-costPerCheckoutPage": {
        active: false,
    },
    "tiktok-completePaymentValuePage": {
        active: false,
    },
    "tiktok-roasPage": {
        active: false,
    },
    "tiktok-salesLead": {
        active: false,
    },
    "tiktok-subscribePage": {
        active: false,
    },
    "tiktok-pageViews": { active: false },
    "tiktok-costPerPageView": { active: false },
    "tiktok-subscribes": { active: false },
    "tiktok-addToCart": { active: false },
    "tiktok-costPerAddToCart": { active: false },
    "tiktok-completePayments": { active: false },
    "tiktok-costPerCompletePayment": { active: false },
    "tiktok-checkouts": { active: false },
    "tiktok-costPerCheckout": { active: false },
    "tiktok-productViews": { active: false },
    "tiktok-costPerProductView": { active: false },
    "tiktok-revenue": { active: false },
    "tiktok-roas": { active: false },
    "pinterest-cpl": {
        active: false,
    },
    "pinterest-cost": {
        active: false,
    },
    "pinterest-conversions": {
        active: false,
    },
    "pinterest-conversionRate": {
        active: false,
    },
    "pinterest-clicks": {
        active: false,
    },
    "pinterest-impressions": {
        active: false,
    },
    "pinterest-ctr": {
        active: false,
    },
    "pinterest-cpc": {
        active: false,
    },
    "pinterest-cpm": {
        active: false,
    },
    "pinterest-reach": {
        active: false,
    },
    "pinterest-frequency": {
        active: false,
    },
    "pinterest-totalEngagements": {
        active: false,
    },
    "pinterest-earnedEngagements": {
        active: false,
    },
    "pinterest-paidEngagements": {
        active: false,
    },
    "pinterest-pageVisit": {
        active: false,
    },
    "pinterest-costPerAddToCart": {
        active: false,
    },
    "pinterest-addToCart": {
        active: false,
    },
    "pinterest-checkout": {
        active: false,
    },
    "pinterest-costPerCheckout": {
        active: false,
    },
    "pinterest-totalOrderValue": {
        active: false,
    },
    "pinterest-totalROAS": {
        active: false,
    },
    "maropost-opened": {
        active: false,
    },
    "maropost-uniqueOpens": {
        active: false,
    },
    "maropost-clicked": {
        active: false,
    },
    "maropost-uniqueClicks": {
        active: false,
    },
    "maropost-sent": {
        active: false,
    },
    "maropost-subscribers": {
        active: false,
    },
    "maropost-unsubscribed": {
        active: false,
    },
    "maropost-bounced": {
        active: false,
    },
    "maropost-openRate": {
        active: false,
    },
    "maropost-ctor": {
        active: false,
    },
    "maropost-ctr": {
        active: false,
    },
    "klaviyo-opened": {
        active: false,
    },
    "klaviyo-uniqueOpens": {
        active: false,
    },
    "klaviyo-clicked": {
        active: false,
    },
    "klaviyo-uniqueClicks": {
        active: false,
    },
    "klaviyo-delivered": {
        active: false,
    },
    "klaviyo-ctr": {
        active: false,
    },
    "klaviyo-unsubscribed": {
        active: false,
    },
    "klaviyo-bounced": {
        active: false,
    },
    "klaviyo-openRate": {
        active: false,
    },
    "klaviyo-ctor": {
        active: false,
    },
    "klaviyo-shopifyOrders": {
        active: false,
    },
    "klaviyo-shopifyRevenue": {
        active: false,
    },
    "klaviyo-shopifyAov": {
        active: false,
    },
    "klaviyo-woocommerceOrders": {
        active: false,
    },
    "klaviyo-woocommerceRevenue": {
        active: false,
    },
    "klaviyo-woocommerceAov": {
        active: false,
    },
    "klaviyo-magentoOrders": {
        active: false,
    },
    "klaviyo-magentoRevenue": {
        active: false,
    },
    "klaviyo-magentoAov": {
        active: false,
    },
    "klaviyo-totalOrders": {
        active: false,
    },
    "klaviyo-totalRevenue": {
        active: false,
    },
    "klaviyo-totalAov": {
        active: false,
    },
    "klaviyo-flowOpened": {
        active: false,
    },
    "klaviyo-flowUniqueOpens": {
        active: false,
    },
    "klaviyo-flowClicked": {
        active: false,
    },
    "klaviyo-flowUniqueClicks": {
        active: false,
    },
    "klaviyo-flowDelivered": {
        active: false,
    },
    "klaviyo-flowCtr": {
        active: false,
    },
    "klaviyo-flowUnsubscribed": {
        active: false,
    },
    "klaviyo-flowBounced": {
        active: false,
    },
    "klaviyo-flowOpenRate": {
        active: false,
    },
    "klaviyo-flowCtor": {
        active: false,
    },
    "klaviyo-flowShopifyOrders": {
        active: false,
    },
    "klaviyo-flowShopifyRevenue": {
        active: false,
    },
    "klaviyo-flowShopifyAov": {
        active: false,
    },
    "klaviyo-flowWoocommerceOrders": {
        active: false,
    },
    "klaviyo-flowWoocommerceRevenue": {
        active: false,
    },
    "klaviyo-flowWoocommerceAov": {
        active: false,
    },
    "klaviyo-flowMagentoOrders": {
        active: false,
    },
    "klaviyo-flowMagentoRevenue": {
        active: false,
    },
    "klaviyo-flowMagentoAov": {
        active: false,
    },
    "klaviyo-flowTotalOrders": {
        active: false,
    },
    "klaviyo-flowTotalRevenue": {
        active: false,
    },
    "klaviyo-flowTotalAov": {
        active: false,
    },
    "klaviyo-listSubscribed": {
        active: false,
    },
    "klaviyo-listUnsubscribed": {
        active: false,
    },
    // Search Console
    "searchConsole-impressions": {
        active: false,
    },
    "searchConsole-clicks": {
        active: false,
    },
    "searchConsole-ctr": {
        active: false,
    },
    "searchConsole-position": {
        active: false,
    },
    // Organic Facebook
    "organicFacebook-totalOrganicImpressions": {
        active: false,
    },
    "organicFacebook-organicEngagements": {
        active: false,
    },
    "organicFacebook-organicEngagementRate": {
        active: false,
    },
    // StackAdapt
    "stackAdapt-cost": { active: false },
    "stackAdapt-impressions": { active: false },
    "stackAdapt-clicks": { active: false },
    "stackAdapt-conversions": { active: false },
    "stackAdapt-engagements": { active: false },
    "stackAdapt-secondaryConversions": { active: false },
    "stackAdapt-conversionRevenue": { active: false },
    "stackAdapt-videoStarts": { active: false },
    "stackAdapt-videoCompletions": { active: false },
    "stackAdapt-uniqueImpressions": { active: false },
    "stackAdapt-conversionRate": { active: false },
    "stackAdapt-cpm": { active: false },
    "stackAdapt-ctr": { active: false },
    "stackAdapt-cpc": { active: false },
    "stackAdapt-cpl": { active: false },
    "stackAdapt-cpe": { active: false },
    "stackAdapt-roas": { active: false },
    "stackAdapt-videoCompletionRate": { active: false },
    "stackAdapt-revenue": { active: false },
    "stackAdapt-revenueFee": { active: false },
};

export const defaultState = {
    defaultGoals,
    selected: {
        "custom-cost": {
            active: true,
        },
        "custom-conversions": {
            active: true,
        },
        "custom-clicks": {
            active: true,
        },
        "custom-impressions": {
            active: true,
        },
        "custom-roas": {
            active: true,
        },
        "custom-paid-clicks": {
            active: true,
        },
    },
    metrics: {
        ...Object.keys(defaultGoals).reduce(
            (cache, metric) => ({
                ...cache,
                [metric]: {
                    totalForCurrentPeriod: 0,
                    totalForPreviousPeriod: 0,
                    type: metric,
                },
            }),
            {},
        ),
    },
    goals: {},
    lastYearGoals: {},
    goalsLoading: null,
    goalsError: {},
    goalsCompareDataLoading: false,
    metricsLoading: false,
    metricsError: false,
    saveGoalsPending: false,
    saveGoalsError: false,
    suggestionPending: {},
    recentlySelected: {},
    suggestionData: {},
    suggestions: {},
    goalDrawerIsOpen: false,
    insights: [],
    insightsPending: false,
    extraCampaignGroupsInfoLoading: false,
    extraCampaignGroupsInfo: {},
    showEditCampaignModal: false,
    editingCampaign: {
        campaignGroupId: "",
        platform: "",
        campaignId: 0,
    },
    reportUi: {
        platform: "all",
        platformSubFilters: {},
        sort: "custom",
        status: "all",
        comparison: "none",
        grid: "medium",
        order: [],
    },
    reportUiPrevious: {
        platform: "all",
        platformSubFilters: {},
        sort: "custom",
        status: "all",
        grid: "medium",
        comparison: "none",
        order: [],
    },
    reportUiPending: null,
    campaignGroupsPerformance: {},
    metricSelectionOpen: false,
    notes: [],
    notesLoading: false,
    notesError: null,
    updateCreateNoteLoading: false,
    updateCreateNoteError: null,
    selectedNoteId: null,
    showCreateNoteDialog: false,
    disabledImproveBenchmark: false,
    kpiSummaryGA4Reports: {},
    kpiSummaryGA4ReportsLoading: false,
    kpiSummaryGA4ReportsError: false,
    kpiSummaryAdwordsTopTerms: {},
    kpiSummaryAdwordsTopTermsLoading: false,
    kpiSummaryAdwordsTopTermsError: false,
    kpiSummaryShopifyTopProducts: {},
    kpiSummaryShopifyTopProductsLoading: false,
    kpiSummaryShopifyTopProductsError: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.UPDATE_REPORT_UI_ORDER]: (state, action) => {
            return {
                ...state,
                reportUi: {
                    ...state.reportUi,
                    order: action.payload.order,
                },
            };
        },
        [types.UPDATE_REPORT_UI]: (state, action) => {
            return merge({}, state, { reportUi: action.payload.ui });
        },
        [types.CLEAR_REPORT_UI_FILTER]: (state, action) => {
            const { reportUi: { [action.filterName]: filter = {} } = {} } = state;

            return {
                ...state,
                reportUi: {
                    ...state.reportUi,
                    [action.filterName]: {
                        ...filter,
                        [action.property]: {},
                    },
                },
            };
        },
        [types.GET_REPORT_UI]: (state, action) => {
            // We dont let users to save camparison anymore
            const { comparison, ...data } = action.payload?.data?.result || {};
            return handle(state, action, {
                start: (prevState) => merge({}, prevState, { reportUiPending: true }),
                success: (prevState) =>
                    merge({}, prevState, {
                        reportUi: data,
                        reportUiPrevious: data,
                        reportUiPending: false,
                    }),
            });
        },
        [types.SAVE_REPORT_UI]: (state, action) => {
            return handle(state, action, {
                success: (prevState) =>
                    merge({}, prevState, {
                        reportUiPrevious: action.payload.ui,
                    }),
            });
        },
        [types.TOGGLE_GOAL_SETTINGS_DRAWER]: (state, action) => {
            return {
                ...state,
                goalDrawerIsOpen: action.isOpen,
            };
        },
        [types.GET_GOAL_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    goalsLoading: !action?.meta?.togglingComparison,
                    goalsError: true,
                    goalsCompareDataLoading: action?.meta?.togglingComparison,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const goals = isCancelledReq ? { ...prevState.goals } : { ...prevState.goals, ...action.payload };
                    return {
                        ...prevState,
                        goalsLoading: isCancelledReq ? true : false,
                        goalsError: false,
                        goalsCompareDataLoading: isCancelledReq ? true : false,
                        goals,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    goalsLoading: false,
                    goalsError: true,
                    goalsCompareDataLoading: false,
                }),
            });
        },
        [types.GET_LAST_YEAR_GOAL_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    lastYearGoalsLoading: !action?.meta?.togglingComparison,
                    lastYearGoalsError: true,
                    lastYearGoalsCompareDataLoading: action?.meta?.togglingComparison,
                }),
                success: (prevState) => ({
                    ...prevState,
                    lastYearGoalsLoading: false,
                    lastYearGoalsError: false,
                    lastYearGoalsCompareDataLoading: false,
                    lastYearGoals: {
                        ...prevState.goals,
                        ...action.payload,
                    },
                }),
                failure: (prevState) => ({
                    ...prevState,
                    lastYearGoalsLoading: false,
                    lastYearGoalsError: true,
                    lastYearGoalsCompareDataLoading: false,
                }),
            });
        },
        [types.CLEAR_GOAL_DATA]: (state, action) => {
            return {
                ...state,
                goals: {},
            };
        },
        [types.REQUEST_BUDGET]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    budgetLoading: true,
                    budget: {},
                }),
                failure: (prevState) => ({
                    ...prevState,
                    budgetError: true,
                }),
                success: (prevState) => ({
                    ...prevState,
                    budget: action.payload.data,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    budgetLoading: false,
                }),
            }),
        [types.REQUEST_LAST_MONTH_BUDGET]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    lastMonthBudgetLoading: true,
                    lastMonthBudget: {},
                }),
                failure: (prevState) => ({
                    ...prevState,
                    lastMonthBudgetError: true,
                }),
                success: (prevState) => ({
                    ...prevState,
                    lastMonthBudget: action.payload.data,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    lastMonthBudgetLoading: false,
                }),
            }),
        [types.LOADING_SUGGESTION]: (state, action) => {
            const { meta: { metrics = [] } = {} } = action;

            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    suggestionPending: {
                        ...prevState.suggestionPending,
                        ...metrics.reduce((obj, metric) => {
                            return {
                                ...obj,
                                [metric]: true,
                            };
                        }, {}),
                    },
                }),
                finish: (prevState) => ({
                    ...prevState,
                    suggestionData: {
                        ...prevState.suggestionData,
                        ...action.payload,
                    },
                    suggestionPending: {
                        ...prevState.suggestionPending,
                        ...metrics.reduce((obj, metric) => {
                            return {
                                ...obj,
                                [metric]: false,
                            };
                        }, {}),
                    },
                }),
            });
        },
        [types.LOADING_RECENTLY_SELECTED]: (state, action) => {
            const metrics = action.payload.metrics || [];
            return {
                ...state,
                recentlySelected: metrics.reduce(
                    (cache, metric) => ({
                        ...cache,
                        [metric]: action.payload.recentlySelected,
                    }),
                    {},
                ),
            };
        },
        [types.SAVE_GOALS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    saveGoalsPending: true,
                }),
                finish: (prevState) => {
                    return {
                        ...prevState,
                        saveGoalsPending: false,
                        saveGoalsError: get(action, ["payload", "data", "error"]) || false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    saveGoalsError: true,
                    saveGoalsPending: false,
                }),
            }),
        [types.GET_CAMPAIGN_GROUPS_PERFORMANCE]: (state, action) => {
            return handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    campaignGroupsPerformance: action.payload,
                }),
                start: (prevState) => ({
                    ...prevState,
                    campaignGroupsPerformancePending: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    campaignGroupsPerformancePending: false,
                }),
            });
        },
        [types.SET_CAMPAIGN_GROUP_PERFORMANCE](prevState, action) {
            return {
                ...prevState,
                campaignGroupsPerformance: action.payload,
            };
        },
        [types.TOGGLE_METRIC_SELECTION]: (state, action) => ({
            ...state,
            metricSelectionOpen: !state.metricSelectionOpen,
        }),
        [types.SET_SELECTED_NOTE_ID]: (state, action) => ({
            ...state,
            selectedNoteId: action.payload,
        }),
        [types.SET_CREATE_NOTE_DIALOG_VISIBLE]: (state, action) => ({
            ...state,
            showCreateNoteDialog: action.payload,
        }),
        [types.GET_NOTES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    notesLoading: true,
                    notesError: null,
                }),
                success: (prevState) => ({
                    ...prevState,
                    notesLoading: false,
                    notesError: false,
                    notes: action?.payload?.data,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    notesLoading: false,
                    notesError: true,
                }),
            });
        },
        [types.CREATE_NOTE]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: true,
                    updateCreateNoteError: null,
                }),
                success: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: null,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: true,
                }),
            });
        },
        [types.UPDATE_NOTE]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: true,
                    updateCreateNoteError: null,
                }),
                success: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: null,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: true,
                }),
            });
        },
        [types.DELETE_NOTE]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: true,
                    updateCreateNoteError: null,
                }),
                success: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: null,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    updateCreateNoteLoading: false,
                    updateCreateNoteError: true,
                }),
            });
        },
        [types.SET_REPORTING_UI_PENDING]: (state, action) => ({
            ...state,
            reportUiPending: action.payload,
        }),
        [types.GET_EXTRA_CAMPAIGN_GROUPS_INFO]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    extraCampaignGroupsInfoLoading: true,
                }),
                success: (prevState) => ({
                    ...prevState,
                    extraCampaignGroupsInfo: action.payload,
                    extraCampaignGroupsInfoLoading: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    extraCampaignGroupsInfoLoading: false,
                }),
            }),
        [types.SET_EDITING_CAMPAIGN](prevState, action) {
            return {
                ...prevState,
                editingCampaign: action.payload,
            };
        },
        [types.SET_SHOW_EDIT_CAMPAIGN_MODAL](prevState, action) {
            return {
                ...prevState,
                showEditCampaignModal: action.payload,
            };
        },
        [types.UPDATE_DISABLE_IMPROVE_BENCHMARK](prevState, action) {
            return {
                ...prevState,
                disabledImproveBenchmark: action.payload,
            };
        },
        [types.UPDATE_PLATFORM_CAMPAIGN]: (state, action) =>
            handle(state, action, {
                success: (prevState) => {
                    const { campaignId, platform, data } = action.meta || {};
                    return merge({}, prevState, {
                        extraCampaignGroupsInfo: {
                            [platform]: {
                                [campaignId]: data,
                            },
                        },
                    });
                },
            }),
        [types.GET_KPI_SUMMARY_GA4_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    kpiSummaryGA4ReportsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const kpiSummaryGA4Reports = isCancelledReq
                        ? { ...prevState.kpiSummaryGA4Reports }
                        : { ...prevState.kpiSummaryGA4Reports, ...action.payload };
                    return {
                        ...prevState,
                        kpiSummaryGA4ReportsLoading: isCancelledReq ? true : false,
                        kpiSummaryGA4ReportsError: false,
                        kpiSummaryGA4Reports,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    kpiSummaryGA4ReportsLoading: false,
                    kpiSummaryGA4ReportsError: true,
                }),
            });
        },
        [types.GET_KPI_SUMMARY_ADWORDS_TOP_TERMS_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    kpiSummaryAdwordsTopTermsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const kpiSummaryAdwordsTopTerms = isCancelledReq
                        ? prevState.kpiSummaryAdwordsTopTerms
                        : { ...payload };

                    return {
                        ...prevState,
                        kpiSummaryAdwordsTopTermsLoading: isCancelledReq ? true : false,
                        kpiSummaryAdwordsTopTermsError: false,
                        kpiSummaryAdwordsTopTerms,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    kpiSummaryAdwordsTopTermsLoading: false,
                    kpiSummaryAdwordsTopTermsError: true,
                }),
            });
        },
        [types.GET_KPI_SUMMARY_SHOPIFY_TOP_PRODUCTS_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    kpiSummaryShopifyTopProductsLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const kpiSummaryShopifyTopProducts = isCancelledReq
                        ? prevState.kpiSummaryShopifyTopProducts
                        : { ...payload };

                    return {
                        ...prevState,
                        kpiSummaryShopifyTopProductsLoading: isCancelledReq ? true : false,
                        kpiSummaryShopifyTopProductsError: false,
                        kpiSummaryShopifyTopProducts,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    kpiSummaryShopifyTopProductsLoading: false,
                    kpiSummaryShopifyTopProductsError: true,
                }),
            });
        },
    }),
);
