import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get } from "lodash";

const defaultState = {
    reports: {},
    loading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.GOOGLE_BUSINESS_PROFILE_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    loading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    loading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);

                    return { ...prevState, reports: isCancelledReq ? prevState.reports : action.payload };
                },
            }),
    }),
);
