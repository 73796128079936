import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get } from "lodash";

const defaultState = {
    report: {},
    organicFacebookLoading: undefined,
    topPosts: {},
    organicFacebookTopPostsLoading: undefined,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ORGANIC_FACEBOOK_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicFacebookLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicFacebookLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { integrationId } = action.meta || {};

                    return {
                        ...prevState,
                        report: { ...prevState.report, [integrationId]: isCancelledReq ? [] : action.payload },
                    };
                },
            }),
        [types.ORGANIC_FACEBOOK_TOP_POSTS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicFacebookTopPostsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicFacebookTopPostsLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { integrationId } = action.meta || {};

                    return {
                        ...prevState,
                        topPosts: { ...prevState.topPosts, [integrationId]: isCancelledReq ? [] : action.payload },
                    };
                },
            }),
    }),
);
