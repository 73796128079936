import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ReactComponent as Settings } from "assets/images/Menu/Icon-Settings.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings.svg";
import { ReactComponent as Changelog } from "assets/images/icons/Icon-Changelog.svg";
import style from "./style.module.scss";
import CustomIconButton from "./CustomIcon";
import ConversionSettings from "assets/images/Menu/JSX/ConversionSettings";
import Integrations from "assets/images/Menu/JSX/Integrations";
import { ReactComponent as IconCampaignGroup } from "assets/images/icons/Icon-CampaignGroup.svg";
import { Permissions } from "features/Permissions";
import MarginIcon from "assets/images/Menu/JSX/Margin";
import ListItem from "./ListItem";
import { makeSelectNavState } from "_redux/users/selectors";
import FreemiumListItem from "./FreemiumListItem";
import { getPathInfo } from "lib/paths";
import { isOnDefaultDomain } from "components/Branding";
import ResegmentIcon from "assets/images/Dashboard/JSX/Resegment";
import { KpiAPI } from "lib/apiKpi";
import { usePrompt } from "components/Prompt/ShortAnnouncement/PromptContext";
import set from "lodash/set";

const SettingsDropdown = ({ selectedSite, history, isNavOpen, toggleModalFeature, upgradeMessage }) => {
    const active = history.location.pathname.split("/")[3] === "settings";

    const conversionActive = active && history.location.pathname.split("/")[4] === "goals";
    const marginActive = active && history.location.pathname.split("/")[4] === "margin";
    const integrationsActive = active && history.location.pathname.split("/")[4] === "integrations";
    const campaignGroupsActive = active && history.location.pathname.split("/")[4] === "campaign-groups";
    const generalActive = active && history.location.pathname.split("/")[4] === "additional";
    const changLogActive = active && history.location.pathname.split("/")[4] === "change-log";

    const { setPromptMessage } = usePrompt();

    return (
        <>
            <div className={style.dropdownContainer}>
                <CustomIconButton
                    active={active}
                    selectedSite={selectedSite}
                    label={"Client Account Settings"}
                    icon={Settings}
                    onClick={() =>
                        history.push(`/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsGoals")}`)
                    }
                    hasSubNav
                    isNavOpen={isNavOpen}
                />
                <div className={`${style.subNavigation} ${style.bottomAligned}`}>
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsGoals")}`)
                        }
                        icon={ConversionSettings}
                        label={"Conversion Settings"}
                        active={conversionActive}
                    />
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsMargin")}`)
                        }
                        icon={MarginIcon}
                        label={"Agency Media Markup"}
                        active={marginActive}
                    />
                    <Permissions actions={["ACTION_ACCOUNT_SETTINGS_INTEGRATIONS"]}>
                        {isOnDefaultDomain() && (
                            <ListItem
                                onClick={() =>
                                    history.push(
                                        `/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsIntegrations")}`,
                                    )
                                }
                                icon={Integrations}
                                label={"Integrations"}
                                active={integrationsActive}
                            />
                        )}
                    </Permissions>
                    <Permissions
                        actions={"ACTION_CAMPAIGN_GROUPS"}
                        fallback={({ ACTION_CAMPAIGN_GROUPS } = {}) => {
                            if (ACTION_CAMPAIGN_GROUPS === upgradeMessage) {
                                return (
                                    <li data-for="navTooltip" data-tip-disable={isNavOpen} data-tip="Campaign Groups">
                                        <FreemiumListItem
                                            icon={IconCampaignGroup}
                                            label={"Campaign Groups"}
                                            active={campaignGroupsActive}
                                            onClick={() => {
                                                toggleModalFeature({
                                                    isOpen: true,
                                                    feature: "Campaign Groups",
                                                    video: "OsJwwot4yU4",
                                                });
                                            }}
                                        />
                                    </li>
                                );
                            }

                            return null;
                        }}
                    >
                        <ListItem
                            onClick={() =>
                                history.push(
                                    `/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsCampaignGroups")}`,
                                )
                            }
                            icon={IconCampaignGroup}
                            label={"Campaign Groups"}
                            active={campaignGroupsActive}
                        />
                    </Permissions>
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsChangeLog")}`)
                        }
                        label="Change Log"
                        icon={Changelog}
                        active={changLogActive}
                    />
                    <Permissions actions={["ACTION_ACCOUNT_SETTINGS_INTEGRATIONS"]}>
                        <ListItem
                            onClick={() =>
                                history.push(
                                    `/dashboard/${selectedSite}/${getPathInfo("Settings", "settingsAdditional")}`,
                                )
                            }
                            label={"Additional Settings"}
                            icon={SettingsIcon}
                            active={generalActive}
                        />
                    </Permissions>
                    <ListItem
                        onClick={async () => {
                            try {
                                const accountId = selectedSite;

                                setPromptMessage("Clearing cache...");

                                await KpiAPI({
                                    url: `/account/${accountId}/flushRedis`,
                                    method: "POST",
                                });

                                setPromptMessage("Cache successfully cleared.");
                            } catch (error) {
                                alert("Failed to clear cache. Please try again.");
                            }
                        }}
                        icon={ResegmentIcon}
                        label={"Clear Cache"}
                    />
                </div>
            </div>
        </>
    );
};

export default compose(
    withRouter,
    connect((state) => ({
        isNavOpen: makeSelectNavState()(state),
    })),
)(SettingsDropdown);
