import { KpiAPI } from "../apiKpi";

function getReport({ accountId, dimensions, dateRange, domain, abortController }) {
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/searchConsole`,
                query: {
                    accountId,
                    dimensions: JSON.stringify(dimensions),
                    dateRange: JSON.stringify([{ start, end }]),
                    domain,
                },
                abortController,
            }),
        ),
    )
        .then(([result = {}, prevResult = {}]) => {
            const { data: resultData } = result;
            const { data: prevResultData } = prevResult;

            if (resultData?.isCancelledReq || prevResultData?.isCancelledReq) {
                return { isCancelledReq: true };
            }

            return {
                current: resultData || [],
                previous: prevResultData || [],
            };
        })
        .catch((error) => {
            console.log("search console", error);
            return [];
        });
}

export default {
    getReport,
};
