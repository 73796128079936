import io from "socket.io-client";

const serverUrl = process.env.REACT_APP_KPI_API_DOMAIN;

function createSocketConnection() {
    const socket = io(serverUrl, {
        reconnection: true,
        reconnectionDelay: 1000, // Reconnect every 1000ms (1 second)
        reconnectionAttempts: 10, // Retry up to 10 times
        transports: ["websocket"],
    });

    socket.on("connect", () => {
        console.log("Socket.io connection opened");
    });

    socket.on("disconnect", () => {
        console.log("Socket.io connection closed");
    });

    socket.on("error", (error) => {
        console.error("Socket.io error:", error);
    });

    return socket;
}

export default createSocketConnection;
