import { api } from "lib/searchConsole";
import types from "./types";

const getSearchConsoleTotalData = ({ dateRange, accountId, domain }) => (dispatch) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_TOTALS,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["date"],
            domain,
        }),
    });
};

const getSearchConsoleQueryData = ({ dateRange, accountId, domain, abortController }) => (dispatch) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_QUERIES,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["query"],
            domain,
            abortController,
        }),
    });
};

const getSearchConsolePagesData = ({ dateRange, accountId, domain, abortController }) => (dispatch) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_PAGES,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["page"],
            domain,
            abortController,
        }),
    });
};

export default {
    getSearchConsoleTotalData,
    getSearchConsoleQueryData,
    getSearchConsolePagesData,
};
