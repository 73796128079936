import React, { Component } from "react";
import style from "./style.module.scss";
import { noop } from "lib/utility";
import { TooltipDark } from "components/Tooltip";
import { SubFilterDropdown } from "components/ToggleMulti";
import ScrollContainer from "react-indiana-drag-scroll";

const checkActiveTab = (isActive, styles) => {
    return isActive ? styles.activeTab : styles.inactiveTab;
};

class TabNavigation extends Component {
    constructor(props) {
        super(props);
        this.activeTab = this.activeTab.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
        this.styles = this.styles.bind(this);
        this.hasChecked = false;
    }

    styles(customStyles) {
        return {
            activeTab: customStyles && customStyles.activeTab ? customStyles.activeTab : style.activeTab,
            inactiveTab: customStyles && customStyles.inactiveTab ? customStyles.inactiveTab : style.inactiveTab,
            alignRight: customStyles && customStyles.alignRight ? customStyles.alignRight : style.alignRight,
        };
    }

    toggleHasChecked(hasChecked) {
        return { hasChecked: hasChecked };
    }

    activeTab(isActive) {
        return checkActiveTab(isActive, this.styles(this.props.customStyles));
    }

    isDisabled(disabled) {
        return typeof disabled === "function" ? disabled() : disabled;
    }

    onClick(disabled, tabType, onClickTab) {
        const isDisabled = this.isDisabled(disabled);
        if (isDisabled) {
            return noop();
        }

        this.props.changeTabType(tabType);
        onClickTab(tabType);
    }

    render() {
        const { tabs = [], className, shouldScrollTab } = this.props;

        const tabsRender = tabs.map(
            (
                {
                    name,
                    info,
                    disabled = false,
                    counter = -1,
                    onClick: onClickTab = noop,
                    url = false,
                    icon,
                    alignRight = false,
                    subFilters = [],
                    subFilterApply,
                    subFilterClear,
                    subFilterSort,
                    subFilterTitle,
                },
                index,
            ) => {
                const isActive = typeof url === "string" ? this.props.tabType === url : this.props.tabType === index;

                return (
                    <li
                        key={url ? url : index}
                        disabled={this.isDisabled(disabled)}
                        onClick={() => {
                            this.onClick(disabled, url ? url : index, onClickTab);
                        }}
                        className={`${this.activeTab(isActive)} ${alignRight &&
                            this.styles(this.props.customStyles)?.alignRight}`}
                        data-tip={info}
                        data-for="tabNav-tooltip"
                        data-html
                    >
                        <span className={style.tabHeader}>
                            {icon && <img src={icon} alt={name} />}
                            {typeof name === "function" ? name(isActive) : name}

                            {/* TODO .. move this into it's component and use functional name*/}
                            {counter > 0 && (
                                <span
                                    className={`${style.counterWrapper} ${
                                        !counter ? style.counterWrapperDisabled : ""
                                    }`}
                                >
                                    {counter}
                                </span>
                            )}
                        </span>
                        {subFilters.length > 0 && (
                            <span>
                                <SubFilterDropdown
                                    subFilters={subFilters}
                                    subFilterApply={subFilterApply}
                                    subFilterClear={subFilterClear}
                                    disabled={disabled}
                                    subFilterSort={subFilterSort}
                                    isActive={isActive}
                                    subFilterTitle={subFilterTitle}
                                    toggleHasChecked={this.toggleHasChecked}
                                />
                            </span>
                        )}
                    </li>
                );
            },
        );

        return (
            <>
                {shouldScrollTab ? (
                    <ScrollContainer
                        className={`${style.tabNavigation} ${style.shouldScroll} ${className && className}`}
                    >
                        {tabsRender}
                    </ScrollContainer>
                ) : (
                    <ul className={`${style.tabNavigation} ${className && className}`}>{tabsRender}</ul>
                )}
                <TooltipDark id="tabNav-tooltip" proximity={this.props.tipProximity || 2} />
            </>
        );
    }
}

export default TabNavigation;
