import { api } from "lib/organicFacebook";
import types from "./types";

const getOrganicFacebookData = ({ dateRange, accountId, integrationId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ORGANIC_FACEBOOK_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
            integrationId,
            abortController,
        }),
        meta: {
            integrationId,
        },
    });
};

const getOrganicFacebookTopPosts = ({ startDate, endDate, accountId, integrationId, abortController }) => (
    dispatch,
) => {
    return dispatch({
        type: types.ORGANIC_FACEBOOK_TOP_POSTS,
        promise: api.getTopPosts({
            startDate,
            endDate,
            accountId,
            integrationId,
            abortController,
        }),
        meta: {
            integrationId,
        },
    });
};

export default {
    getOrganicFacebookData,
    getOrganicFacebookTopPosts,
};
