import { channelFriendlyName } from "lib/utility";
import { isEmpty } from "lodash";

const PMAX_CAMPAIGN_NETWORK = "Cross-network";

export const ADWORDS_NETWORKS = {
    adwordsDisplay: {
        enumValue: "DISPLAY",
        friendlyName: "Display Network",
    },
    adwordsSearch: {
        enumValue: "SEARCH",
        friendlyName: "Search Network",
    },
    adwordsYouTubeVideos: {
        enumValue: "YOUTUBE",
        friendlyName: "YouTube Network",
    },
    adwordsSearchPartners: {
        enumValue: "SEARCH_PARTNERS",
        friendlyName: "Search Partners",
    },
    adwordsCross: {
        enumValue: "MIXED",
        friendlyName: PMAX_CAMPAIGN_NETWORK,
    },
};

export const ADWORDS_NETWORK_OPTIONS = Object.keys(ADWORDS_NETWORKS).map((network) => ({
    key: network,
    name: ADWORDS_NETWORKS[network].friendlyName,
}));

export const ADWORDS_NETWORK_KEYS = Object.keys(ADWORDS_NETWORKS);

export const filterNetworkCampaigns = ({ campaigns, network }) => {
    if (!network) {
        return campaigns;
    }

    return campaigns.filter((campaign) => {
        const { network: campaignNetwork } = campaign;
        return campaignNetwork === ADWORDS_NETWORKS[network]?.enumValue;
    });
};

export const isPMaxNetwork = (network) => {
    if (isEmpty(network)) {
        return false;
    }

    if (Array.isArray(network)) {
        return network.length === 1 && network.includes(PMAX_CAMPAIGN_NETWORK);
    }

    return network === PMAX_CAMPAIGN_NETWORK;
};

export const getNetworkKey = (networkFriendlyName = "") => {
    if (!networkFriendlyName) {
        return "";
    }

    if (networkFriendlyName === channelFriendlyName("adwords")) {
        return "adwords";
    }

    return Object.keys(ADWORDS_NETWORKS).find(
        (network) => ADWORDS_NETWORKS[network]?.friendlyName === networkFriendlyName,
    );
};
