import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get, upperFirst } from "lodash";

export const defaultState = {
    bingTopTermsReport: {},
    bingTopTermsReportLoading: false,
    bingTopTermsReportError: false,
    showCardSettingsSidebar: false,
    editingCard: {},
    tabIndexSidebar: 0,
    sidebarIntegration: {},
    sidebarCardType: "kpi",
    sidebarSearchInput: "",
};

let benchmarkReducers = {};

Object.keys(types.GET_REPORT_BUILDER_BENCHMARK_DATA).forEach((platform) => {
    Object.keys(types.GET_REPORT_BUILDER_BENCHMARK_DATA[platform]).forEach((benchmarkType) => {
        const benchmarkTypeAction = types.GET_REPORT_BUILDER_BENCHMARK_DATA[platform][benchmarkType];
        const benchmarkTypeKey = upperFirst(benchmarkType.split("-")[1] || "");
        const benchmarkTypeLoadingKey = `${platform}${benchmarkTypeKey}BenchmarkLoading`;
        const benchmarkTypeErrorKey = `${platform}${benchmarkTypeKey}BenchmarkError`;
        const benchmarkTypeDataKey = `${platform}${benchmarkTypeKey}BenchmarkReport`;
        benchmarkReducers = {
            ...benchmarkReducers,
            [benchmarkTypeAction]: (state, action) => {
                return handle(state, action, {
                    start: (prevState) => ({
                        ...prevState,
                        [benchmarkTypeLoadingKey]: true,
                    }),
                    success: (prevState) => {
                        const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                        const { payload = {} } = action || {};
                        const benchmarkData = isCancelledReq ? prevState[benchmarkTypeDataKey] : { ...payload };

                        return {
                            ...prevState,
                            [benchmarkTypeLoadingKey]: isCancelledReq ? true : false,
                            [benchmarkTypeErrorKey]: false,
                            [benchmarkTypeDataKey]: benchmarkData,
                        };
                    },
                    failure: (prevState) => ({
                        ...prevState,
                        [benchmarkTypeLoadingKey]: false,
                        [benchmarkTypeErrorKey]: true,
                    }),
                });
            },
        };
    });
});

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        ...benchmarkReducers,
        [types.GET_REPORT_BING_TOP_TERMS_DATA]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    bingTopTermsReportLoading: true,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const { payload = {} } = action;
                    const bingTopTermsReport = isCancelledReq ? prevState.bingTopTermsReport : { ...payload };

                    return {
                        ...prevState,
                        bingTopTermsReportLoading: isCancelledReq ? true : false,
                        bingTopTermsReportError: false,
                        bingTopTermsReport,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    bingTopTermsReportLoading: false,
                    bingTopTermsReportError: true,
                }),
            });
        },
        [types.SET_SHOW_CARD_SETTINGS_SIDEBAR]: (state, action) => {
            return {
                ...state,
                showCardSettingsSidebar: action.payload,
            };
        },
        [types.SET_EDITING_CARD]: (state, action) => {
            return {
                ...state,
                editingCard: action.payload,
            };
        },
        [types.SET_TAB_INDEX_SIDEBAR]: (state, action) => {
            return {
                ...state,
                tabIndexSidebar: action.payload,
            };
        },
        [types.SET_SIDEBAR_INTEGRATION]: (state, action) => {
            return {
                ...state,
                sidebarIntegration: action.payload,
            };
        },
        [types.SET_SIDEBAR_CARD_TYPE]: (state, action) => {
            return {
                ...state,
                sidebarCardType: action.payload,
            };
        },
        [types.SET_SIDEBAR_SEARCH_INPUT]: (state, action) => {
            return {
                ...state,
                sidebarSearchInput: action.payload,
            };
        },
    }),
);
