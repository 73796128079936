import { divide } from "lib/metricCalculations";

export const adwordsMetrics = (metrics) => {
    const {
        cost = 0,
        impressions = 0,
        conversions = 0,
        clicks = 0,
        views = 0,
        network,
        conversionValue = 0,
        conversionsValueByConversionDate = 0,
        phoneCalls = 0,
        phoneImpressions = 0,
        ...props
    } = metrics;

    const adjustedReport = {
        ...props,
        cost: parseFloat(cost),
        impressions: parseFloat(impressions),
        clicks: parseFloat(clicks),
        conversions: parseFloat(conversions),
        network,
        views: parseFloat(views),
        phoneCalls: parseInt(phoneCalls),
        phoneImpressions: parseInt(phoneImpressions),
        conversionValue: parseFloat(conversionValue),
        conversionsValueByConversionDate: parseFloat(conversionsValueByConversionDate),
    };

    adjustedReport.conversionRate = (adjustedReport.conversions / adjustedReport.clicks) * 100;
    adjustedReport.viewRate = (adjustedReport.views / adjustedReport.impressions) * 100;
    adjustedReport.ctr = (adjustedReport.clicks / adjustedReport.impressions) * 100;
    adjustedReport.cpl = adjustedReport.cost / adjustedReport.conversions;
    adjustedReport.cpv = adjustedReport.cost / adjustedReport.views;
    adjustedReport.cpc = adjustedReport.cost / adjustedReport.clicks;
    adjustedReport.cpm = (1000 * adjustedReport.cost) / adjustedReport.impressions;
    adjustedReport.roas = divide(adjustedReport.conversionValue, adjustedReport.cost, 0);
    adjustedReport.roasByTime = divide(adjustedReport.conversionsValueByConversionDate, adjustedReport.cost, 0);
    adjustedReport.phoneThroughRate = 100 * divide(adjustedReport.phoneCalls, adjustedReport.phoneImpressions, 0);

    return adjustedReport;
};

export const formatSearchTermMetrics = (metrics) => {
    const { cost = 0, impressions = 0, conversions = 0, clicks = 0, ...props } = metrics;

    const adjustedReport = {
        ...props,
        cost: parseFloat(cost),
        impressions: parseInt(impressions),
        clicks: parseInt(clicks),
        conversions: parseFloat(conversions),
    };
    adjustedReport.ctr = 100 * divide(adjustedReport.clicks, adjustedReport.impressions, 0);
    adjustedReport.cpl = divide(adjustedReport.cost, adjustedReport.conversions, 0);

    return adjustedReport;
};
