import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M8,14 C5.20038136,14 3.30351177,12.5961361 2.5,9.90089502 C2.37447067,9.49596129 1.65175589,9.37343309 1.25,9.5 C0.848244113,9.62652225 0.374426361,10.0950663 0.5,10.5 C1.5169072,13.8911804 4.48478,16 8,16 C12.356395,16 16,12.3908536 16,8 C16,3.6091464 12.356395,1.77635684e-15 8,1.77635684e-15 C5.52676648,1.77635684e-15 3.48144991,1.0766206 2,3 L2,1 C2,0.5 1.5,1.77635684e-15 1,5.86197757e-14 C0.5,1.15478406e-13 8.93556062e-15,0.5 4.05492914e-13,1 L6.03961325e-14,6 C6.03961325e-14,6.5 0.15,7 1,7 C1.43333333,7 2.76666667,7 5,7 C5.5,7 6,6.5 6,6 C6,5.5 5.5,5 5,5 L3,5 C4.18009976,3.21582634 5.84061196,2 8,2 C10.9092339,2 14,4.44429151 14,8 C14,11.5557085 10.9092782,14 8,14 Z"
            id="Path-Copy"
            transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) "
        ></path>
    </svg>
);
