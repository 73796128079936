import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import get from "lodash/get";

const defaultState = {
    totals: {},
    pages: {},
    queries: {},
    totalsLoading: false,
    pagesLoading: false,
    queriesLoading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.SEARCH_CONSOLE_TOTALS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    totalsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    totalsLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const totals = isCancelledReq ? prevState.totals : action.payload;

                    return {
                        ...prevState,
                        totals,
                    };
                },
            }),
        [types.SEARCH_CONSOLE_PAGES]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pagesLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pagesLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const pages = isCancelledReq ? prevState.pages : action.payload;

                    return {
                        ...prevState,
                        pages,
                    };
                },
            }),
        [types.SEARCH_CONSOLE_QUERIES]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    queriesLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    queriesLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const queries = isCancelledReq ? prevState.queries : action.payload;

                    return {
                        ...prevState,
                        queries,
                    };
                },
            }),
    }),
);
