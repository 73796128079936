import React from "react";

export default (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="#BCCFDC"
        {...props}
    >
        <path
            d="M2.45106493,44.0004342 C2.29916538,44.0060538 2.15806764,44.0804568 2.0676271,44.2026271 C1.97718657,44.3247973 1.94722237,44.4814706 1.98619839,44.6283921 L3.65229055,50.872822 L11.0090763,51.9988521 L3.65327061,53.1239033 L1.98619839,59.3702872 C1.93509241,59.560692 2.00062308,59.7633542 2.15353281,59.8877907 C2.30644253,60.0122272 2.51819204,60.0352131 2.69424119,59.9464859 L17.694936,52.4461383 C17.8638258,52.3612403 17.9704113,52.1883673 17.9704113,51.9993397 C17.9704113,51.8103121 17.8638258,51.6374391 17.694936,51.552541 L2.69424119,44.0521935 C2.61883258,44.0147427 2.53518877,43.9969381 2.45106493,44.0004304 L2.45106493,44.0004342 Z"
            transform="translate(-1.97 -44)"
        />
    </svg>
);
