import types from "./types";
import { getPlatformCampaignsReport } from "lib/reports/campaigns/api";

const getBingData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.BING_REPORT,
        promise: getPlatformCampaignsReport({
            platform: "bing",
            dateRange,
            accountId,
            abortController,
        }),
    });
};

export default {
    getBingData,
};
