import { KpiAPI } from "../apiKpi";

export function getAccounts({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/linkedin/accounts`,
        query: {
            userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "linkedin",
                integrations: {
                    linkedin: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getCampaigns({ userId, connectionId, linkedinId }) {
    return KpiAPI({
        method: "GET",
        url: `/linkedin/campaigns`,
        query: {
            userId,
            connectionId,
            linkedinId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                linkedinId,
                campaigns: {
                    linkedin: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getAccounts,
    getCampaigns,
};
