import React, { useRef } from "react";
import style from "./style.module.scss";
import Footer from "../Footer";

export let mainPanelRef;

export default ({
    children,
    style: inlineStyle,
    className,
    hasFooter = true,
    fullWidth = false,
    fullWidthAndPadding = false,
    noTopPadding = false,
}) => {
    mainPanelRef = useRef(null);

    const containerStyles = {
        ...(fullWidth ? { padding: 0, width: "100%" } : {}),
        ...(fullWidthAndPadding ? { padding: "0 4rem", width: "100%" } : {}),
    };

    return (
        <div
            ref={mainPanelRef}
            id="mainPanel"
            style={inlineStyle}
            className={`${className && className} ${style.mainPanel}`}
        >
            <div className={style.mainContent} style={{ paddingTop: noTopPadding ? 0 : "2rem" }}>
                <div style={containerStyles}>{children}</div>
                {hasFooter && <Footer />}
            </div>
        </div>
    );
};
