import { KpiAPI } from "lib/apiKpi";

export function getCampaigns({ isSandboxAccount, apiKey }) {
    return KpiAPI({
        method: "GET",
        url: `/stackAdapt/campaigns`,
        query: {
            isSandboxAccount,
            apiKey,
        },
    })
        .then(({ data }) => {
            return {
                isSandboxAccount,
                apiKey,
                campaigns: {
                    stackAdapt: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getCampaigns,
};
