import { api } from "lib/adwords";
import types from "./types";
import { getPlatformCampaignsReport } from "lib/reports/campaigns/api";

const getAdwordsCampaignData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ADWORDS_CAMPAIGN_REPORT,
        promise: getPlatformCampaignsReport({
            platform: "adwords",
            dateRange,
            accountId,
            abortController,
        }),
    });
};

const getSearchTermsReport = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: types.ADWORDS_SEARCH_TERMS_REPORT,
        promise: api.getSearchTermsReport({
            dateRange,
            accountId,
        }),
    });
};

export const getCampaignAssetPerformance = ({ dateRange, accountId, comparison, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ADWORDS_CAMPAIGN_ASSET_REPORT,
        promise: api.getCampaignAssetReport({
            dateRange,
            accountId,
            comparison,
            abortController,
        }),
    });
};

export const getAssetGroupPerformance = ({ dateRange, accountId, comparison, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ADWORDS_ASSET_GROUP_REPORTS,
        promise: api.getAssetGroupReport({
            dateRange,
            accountId,
            comparison,
            abortController,
        }),
    });
};

export const getAssetGroupAssets = ({ accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.ADWORDS_ASSET_GROUP_ASSETS,
        promise: api.getAssetGroupAssets({
            accountId,
            abortController,
        }),
    });
};

export default {
    getAdwordsCampaignData,
    getSearchTermsReport,
    getCampaignAssetPerformance,
    getAssetGroupPerformance,
    getAssetGroupAssets,
};
