import { KpiAPI } from "lib/apiKpi";
import { formatDateToString } from "lib/dateUtils";

export function getCampaigns({ apiKey }) {
    return KpiAPI({
        method: "GET",
        url: `/klaviyo/campaigns`,
        query: {
            apiKey,
        },
    })
        .then(({ data }) => {
            return {
                apiKey,
                campaigns: {
                    klaviyo: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getCampaignReport({ dateRange, accountId, abortController }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: formatDateToString(start),
                    end: formatDateToString(end),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/klaviyo/reports",
        query: {
            accountId,
            dateRange,
            startDate: formatDateToString(dateRange.start),
            endDate: formatDateToString(dateRange.end),
        },
        abortController,
    }).then(({ data = [] } = {}) => {
        return data;
    });
}

export default {
    getCampaigns,
    getCampaignReport,
};
