import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import { KLAVIYO_CAMPAIGN_REPORT } from "./types";
import { handle } from "redux-pack";
import get from "lodash/get";

const defaultState = {
    report: [],
    klaviyoLoading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [KLAVIYO_CAMPAIGN_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    klaviyoLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    klaviyoLoading: false,
                }),
                success: (prevState) => {
                    const isCancelledReq = get(action, ["payload", "isCancelledReq"]);
                    const report = isCancelledReq ? prevState.report : action.payload;

                    return {
                        ...prevState,
                        report,
                    };
                },
            });
        },
    }),
);
